import along from "@turf/along";
import circle from "@turf/circle";
import length from "@turf/length";
import { lineString } from "@turf/helpers";
import { isGeofenceArray, validateCoordinates } from "./utils";
const GEOFENCE_ID_REGEX = /^[-._\p{L}\p{N}]+$/iu;
export const getGeofenceFeatureArray = (data) => {
    const coordinates = isGeofenceArray(data)
        ? data.map((geofence) => geofence.geometry.polygon)
        : data;
    return {
        type: "Feature",
        geometry: {
            type: "MultiPolygon",
            coordinates,
        },
        properties: {},
    };
};
export const getGeofenceFeatureFromPolygon = (polygon) => {
    return {
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: polygon,
        },
        properties: {},
    };
};
// Measures distance between the coordinate bounds and takes two points 1/4 from each coordinate to create a polygon
export const getPolygonFeatureFromBounds = (id, bounds) => {
    const swCoordinate = bounds.getSouthWest().toArray();
    const neCoordinate = bounds.getNorthEast().toArray();
    const center = bounds.getCenter().toArray();
    const line = lineString([swCoordinate, center, neCoordinate]);
    const distanceInMiles = length(line, { units: "miles" });
    // Gets coordinates 1/4 along the line from each coordinate
    const southWestCoordinate = along(line, distanceInMiles / 4, {
        units: "miles",
    }).geometry.coordinates;
    const northeastCoordinate = along(line, distanceInMiles * (3 / 4), {
        units: "miles",
    }).geometry.coordinates;
    // Creates a polygon from the coordinates found along the line between the bounding coordinates in counter clockwise order starting from northeast most coordinate
    const polygon = [
        [
            northeastCoordinate,
            [southWestCoordinate[0], northeastCoordinate[1]],
            southWestCoordinate,
            [northeastCoordinate[0], southWestCoordinate[1]],
            northeastCoordinate,
        ],
    ];
    return {
        id,
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: polygon,
        },
        properties: {},
    };
};
export const getCircleFeatureFromCoords = (id, center, { bounds, radius }) => {
    if (!bounds && !radius) {
        throw new Error("Circle requires a bounds or a radius");
    }
    validateCoordinates(center);
    const circleRadius = radius !== null && radius !== void 0 ? radius : getDistanceFromBounds(bounds) / 8;
    const circleFeature = circle(center, circleRadius, { units: "miles" });
    return {
        id,
        type: "Feature",
        properties: {
            isCircle: true,
            center,
            radius: circleRadius,
        },
        geometry: {
            type: "Polygon",
            coordinates: circleFeature.geometry.coordinates,
        },
    };
};
const getDistanceFromBounds = (bounds) => {
    const swCoordinate = bounds.getSouthWest().toArray();
    const neCoordinate = bounds.getNorthEast().toArray();
    const center = bounds.getCenter().toArray();
    const line = lineString([swCoordinate, center, neCoordinate]);
    return length(line, { units: "miles" });
};
export const doesGeofenceExist = (id, loadedGeofences) => {
    return !!loadedGeofences[id];
};
export const isValidGeofenceId = (id) => {
    return !!id.match(GEOFENCE_ID_REGEX);
};
export const isExistingGeofenceId = (id, loadedGeofences) => {
    return doesGeofenceExist(id, loadedGeofences);
};
export const isGeofenceDisplayed = (id, displayedGeofences) => {
    return !!displayedGeofences.find((geofence) => geofence.geofenceId === id);
};
export const getDistanceBetweenCoordinates = (startCoord, endCoord) => {
    const line = lineString([startCoord, endCoord]);
    const distanceInMiles = length(line, { units: "miles" });
    return distanceInMiles;
};
