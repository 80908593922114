import { COLOR_BLACK } from "./constants";
import { getGeofenceFeatureArray } from "./geofenceUtils";
import { isGeofenceArray, isPolygonArray } from "./utils";
const FILL_OPACITY = 0.3;
const BORDER_OPACITY = 0.5;
const BORDER_WIDTH = 4;
/**
 * DrawPoints utility function for adding points to a map based on coordinate data or a FeatureCollection. Will add clustered points and styled markers by default with options for popups and other styles
 */
export function drawGeofences(sourceName, data, map, options = {}) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!map ||
        typeof map.addSource !== "function" ||
        typeof map.addLayer !== "function") {
        throw new Error("Please use a maplibre map");
    }
    if (data.length > 0 && !isGeofenceArray(data) && !isPolygonArray(data)) {
        throw new Error("Please pass in an array of Geofences or an array of Polygons");
    }
    /*
     * Data source for features
     * Convert data passed in as coordinates into feature data
     */
    const sourceId = `${sourceName}`;
    map.addSource(sourceId, {
        type: "geojson",
        data: getGeofenceFeatureArray(data),
        generateId: true,
    });
    const initialVisiblity = ((_a = options.visible) !== null && _a !== void 0 ? _a : true) ? "visible" : "none";
    /*
     * Draw ui layers for source data
     */
    const fillLayerId = `${sourceName}-fill-layer`;
    map.addLayer({
        id: fillLayerId,
        type: "fill",
        source: sourceId,
        layout: {
            visibility: initialVisiblity,
        },
        paint: {
            "fill-color": (_b = options.fillColor) !== null && _b !== void 0 ? _b : COLOR_BLACK,
            "fill-opacity": (_c = options.fillOpacity) !== null && _c !== void 0 ? _c : FILL_OPACITY,
        },
    });
    // Add a black outline around the polygon.
    const outlineLayerId = `${sourceName}-outline-layer`;
    map.addLayer({
        id: outlineLayerId,
        type: "line",
        source: sourceId,
        layout: {
            visibility: initialVisiblity,
        },
        paint: {
            "line-color": (_d = options.borderColor) !== null && _d !== void 0 ? _d : COLOR_BLACK,
            "line-opacity": (_e = options.borderOpacity) !== null && _e !== void 0 ? _e : BORDER_OPACITY,
            "line-width": (_f = options.borderWidth) !== null && _f !== void 0 ? _f : BORDER_WIDTH,
            "line-offset": (_g = options.borderOffset) !== null && _g !== void 0 ? _g : (((_h = options.borderWidth) !== null && _h !== void 0 ? _h : BORDER_WIDTH) / 2) * -1,
        },
    });
    // utility function for setting layer visibility to none
    const hide = () => {
        map.setLayoutProperty(fillLayerId, "visibility", "none");
        map.setLayoutProperty(outlineLayerId, "visibility", "none");
    };
    // utility function for setting layer visibility to visible
    const show = () => {
        map.setLayoutProperty(fillLayerId, "visibility", "visible");
        map.setLayoutProperty(outlineLayerId, "visibility", "visible");
    };
    // utility function for checking layer visibility
    const isVisible = () => {
        const visibility = map.getLayoutProperty(fillLayerId, "visibility");
        return visibility === "visible";
    };
    // utility function for setting layer visibility to visible
    const setData = (data) => {
        map.getSource(sourceId).setData(data);
    };
    return {
        sourceId,
        outlineLayerId,
        fillLayerId,
        show,
        hide,
        isVisible,
        setData,
    };
}
